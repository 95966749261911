import Vue from "vue";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

// Here you can include some "default" settings
iziToast.settings({
  close: false,
  pauseOnHover: false,
  timeout: 5000,
  progressBar: true,
  layout: 1,
  position: "bottomCenter",
});

// and export it
export default function install(): void {
  Object.defineProperties(Vue.prototype, {
    $iziToast: {
      get() {
        return iziToast;
      },
    },
  });
}
