import HttpService from "../HttpService";

import User from "@/models/basedata/User";

class AuthService {
  async login(user: User) {
    const authResponse = await HttpService.post<User>("auth/login/", user);
    if(authResponse !== null && authResponse !== undefined) {
      localStorage.setItem("user", JSON.stringify(authResponse));
      let userResponse = await HttpService.get<User>("auth/me/");
      if(userResponse !== null && userResponse !==undefined){
        userResponse.access = authResponse?.access as string;
        userResponse.refresh = authResponse?.refresh as string;
      }
      
      localStorage.setItem("user", JSON.stringify(userResponse));
      return userResponse;
    }
    throw "Login failed";
  }

  async refresh() {
    try {
      const user = new User(JSON.parse("" + localStorage.getItem("user")));
      const authResponse = await HttpService.post<User>("auth/token-refresh/", user);
      if (authResponse !== undefined && authResponse.access) {
        user.access = authResponse.access;
        localStorage.setItem("user", JSON.stringify(user));
      }
    } catch {
      // no valid user data
      this.logout();
    }
  }

  logout() {
    localStorage.removeItem("user");
    window.location.href = "/login";
  }

  /*
  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }*/

  GetAuthHeader(contenttype = "application/json") {
    const user = JSON.parse("" + localStorage.getItem("user"));

    const headers = {
      "Content-Type": contenttype,
      "Access-Control-Allow-Headers":
        "Accept, Content-Type, Authorization, Access-Control-Allow-Origin",
    };
    if (user && user.access) {
      return Object.assign(headers, { Authorization: `Bearer ${user.access}` });
    } else {
      return headers;
    }
  }
}

export default new AuthService();
