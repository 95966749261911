import dotenv from "dotenv";
dotenv.config();

export default class ConfigProvider {
  public static value(name: string): string {
    let value = "";
    if (name == "backendHost") {
      value = "$VUE_APP_BASE_URL";
    }

    if (value.startsWith("$VUE_APP_")) {
      const envName = value.substr(1);
      const envValue = process.env[envName];
      if (envValue) {
        return envValue;
      } else {
        return "";
      }
    } else {
      return value;
    }
  }
}
