export default class AgePointCalculation {
  id?: string;
  name = "";
  fromTheAge = 0;
  pointsPerYearOfAge = 0.0;
  exceedMaximumValuePoints = false;
  exceedMaximumTotalPoints = false;
  addToAValue = false;
  addToBValue = false;
  addToSum = false;

  constructor(agePointCalculation?: AgePointCalculation) {
    if (agePointCalculation === undefined || agePointCalculation === null) {
      return;
    }
    this.id = agePointCalculation.id;
    this.name = agePointCalculation.name;
    this.fromTheAge = agePointCalculation.fromTheAge;
    this.pointsPerYearOfAge = agePointCalculation.pointsPerYearOfAge;
    this.exceedMaximumValuePoints =
      agePointCalculation.exceedMaximumValuePoints;
    this.exceedMaximumTotalPoints =
      agePointCalculation.exceedMaximumTotalPoints;
    this.addToAValue = agePointCalculation.addToAValue;
    this.addToBValue = agePointCalculation.addToBValue;
    this.addToSum = agePointCalculation.addToSum;
  }
}
