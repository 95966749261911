/* eslint-disable */
import SporteventService from "../services/sportevent/SporteventService";
import Sportevent from "@/models/sportevent/Sportevent";

let sportevent = JSON.parse("" + localStorage.getItem("sportevent"));
const initialState = sportevent ? { sportevent } : { sportevent: null };

export default {
  namespaced: true,
  state: initialState,
  actions: {
    set(context: any, sportevent: Sportevent) {
      if (sportevent.id != undefined) {
        return SporteventService.getWithApi(sportevent.id).then(
          (sportevent: Sportevent) => {
            context.commit("setSportEventMutation", sportevent);
            return Promise.resolve(sportevent);
          }
        );
      } else {
        context.commit("setSportEventMutation", null);
      }
    },
    clear(context: any): void {
      context.commit("setSportEventMutation", null);
    },
  },
  mutations: {
    setSportEventMutation(state: any, sportevent: Sportevent) {
      localStorage.setItem("sportevent", JSON.stringify(sportevent));
      state.sportevent = sportevent;
    },
  },
};
