export default class StartgroupName {
  id?: string;
  name = "";
  pluralName = "";

  constructor(startgroupName?: StartgroupName) {
    if (startgroupName === undefined || startgroupName === null) {
      return;
    }
    this.id = startgroupName.id;
    this.name = startgroupName.name;
    this.pluralName = startgroupName.pluralName;
  }
}
