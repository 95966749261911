/* eslint-disable */

import Society from "@/models/societymgmt/Society";

function sortEntries(a: Society, b: Society): number {
  if (a.shortname < b.shortname) return -1;
  if (a.shortname > b.shortname) return 1;
  return 0;
}

const storage_name = "societies";
let entries = JSON.parse("" + localStorage.getItem(storage_name));

export default {
  namespaced: true,
  state: entries ? { entries } : { entries: [] },
  actions: {
    setAll(context: any, entries: Society[]): void {
      context.commit("setAllMutation", entries);
      context.commit("safeState");
    },
    add(context: any, entry: Society): void {
      context.commit("addMutation", entry);
      context.commit("safeState");
    },
    delete(context: any, idToDelete: string): void {
      context.commit("deleteMutation", idToDelete);
      context.commit("safeState");
    },
    update(context: any, entry: Society): void {
      context.commit("updateMutation", entry);
      context.commit("safeState");
    },
    clear(context: any): void {
      context.commit("setAllMutation", []);
      context.commit("safeState");
    },
  },
  mutations: {
    safeState(state: any): void {
      localStorage.setItem(storage_name, JSON.stringify(state.entries));
    },
    setAllMutation(state: any, entries: Society[]) {
      if (entries !== undefined) {
        state.entries = entries.sort(sortEntries);
      } else {
        state.entries = [];
      }
    },
    addMutation(state: any, entry: Society) {
      state.entries.push(entry);
      state.entries = state.entries.sort(sortEntries);
    },
    updateMutation(state: any, entry: Society): void {
      if (entry.id !== undefined) {
        let index = state.entries.findIndex((e: Society) => e.id == entry.id);
        state.entries[index] = entry;
      }
    },
    deleteMutation(state: any, delEntryId: string) {
      if (delEntryId !== undefined) {
        state.entries = state.entries.filter(
          (e: Society) => e.id !== delEntryId
        );
      }
    },
  },
};
