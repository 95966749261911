/* eslint-disable */

import SporteventCompetition from "@/models/sportevent/SporteventCompetition";

const storage_name = "sporteventcompetitions";

let entries = JSON.parse("" + localStorage.getItem(storage_name));

function sortEntries(
  a: SporteventCompetition,
  b: SporteventCompetition
): number {
  return a.competition.name > b.competition.name ? 1 : -1;
}

export default {
  namespaced: true,
  state: entries ? { entries } : { entries: [] },
  actions: {
    add(context: any, entry: SporteventCompetition): void {
      context.commit("addMutation", entry);
      context.commit("safeState");
    },
    delete(context: any, idToDelete: string): void {
      context.commit("deleteMutation", idToDelete);
      context.commit("safeState");
    },
    update(context: any, entry: SporteventCompetition): void {
      context.commit("updateMutation", entry);
      context.commit("safeState");
    },
    clear(context: any): void {
      context.commit("setAllMutation", []);
      context.commit("safeState");
    },
  },
  mutations: {
    safeState(state: any): void {
      localStorage.setItem(storage_name, JSON.stringify(state.entries));
    },
    setAllMutation(state: any, entries: SporteventCompetition[]) {
      if (entries !== undefined && entries.length > 0) {
        state.entries = entries.sort(sortEntries);
      } else {
        state.entries = [];
      }
    },
    addMutation(state: any, entry: SporteventCompetition) {
      state.entries.push(entry);
      state.entries = state.entries.sort(sortEntries);
    },
    updateMutation(state: any, entry: SporteventCompetition): void {
      let index = state.entries.findIndex(
        (e: SporteventCompetition) => e.id == entry.id
      );
      state.entries[index] = entry;
    },
    deleteMutation(state: any, delEntryId: string) {
      if (delEntryId !== undefined) {
        state.entries = state.entries.filter(
          (e: SporteventCompetition) => e.id !== delEntryId
        );
      }
    },
  },
};
