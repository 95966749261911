class PrintSettingsService {
  public showHeader = true;
  public showFooter = true;

  constructor() {
    const show_header = JSON.parse(
      "" + localStorage.getItem("show_print_header")
    ) as boolean;
    if (show_header !== undefined) {
      this.showHeader = show_header;
    } else {
      this.setShowHeaderFooter();
    }
    const show_footer = JSON.parse(
      "" + localStorage.getItem("show_print_footer")
    ) as boolean;
    if (show_footer !== undefined) {
      this.showFooter = show_footer;
    } else {
      this.setShowHeaderFooter();
    }
  }

  async setHeader(hide = false): Promise<void> {
    if (hide === true) {
      await this.setHideHeaderFooter();
    } else {
      await this.setShowHeaderFooter();
    }
  }

  async setHideHeaderFooter(): Promise<void> {
    this.showHeader = false;
    this.showFooter = false;
    localStorage.setItem("show_print_header", JSON.stringify(this.showHeader));
    localStorage.setItem("show_print_footer", JSON.stringify(this.showFooter));
  }
  async setShowHeaderFooter(): Promise<void> {
    this.showHeader = true;
    this.showFooter = true;
    localStorage.setItem("show_print_header", JSON.stringify(this.showHeader));
    localStorage.setItem("show_print_footer", JSON.stringify(this.showFooter));
  }

  public getShowHeader(): boolean {
    return this.showHeader;
  }

  public getShowFooter(): boolean {
    return this.showFooter;
  }
}

export default new PrintSettingsService();
