import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/plugins/i18n";

Vue.config.productionTip = false;

/* Bootstrap and Fonts */
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

/* Toasts */
import iziToast from "@/plugins/toasts";
Vue.use(iziToast);

Vue.prototype.$isLocalEnv = process.env.NODE_ENV === "development";


/* Loading Bars */
import VueLoading, { VueLoadingOptions } from "vue-loading-template";

const loadingOptions: VueLoadingOptions = {
  type: "bars",
  color: "#ff6d00",
  size: { width: "2em", height: "2em" },
};
Vue.use<VueLoadingOptions>(VueLoading, loadingOptions);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
