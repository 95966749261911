import iziToast from "izitoast";
class ToastService {
  async Info(title: string, message: string) {
    iziToast.info({
      title: title,
      message: message,
    });
  }

  async Success(title: string, message: string) {
    iziToast.success({
      title: title,
      message: message,
    });
  }

  async Warning(title: string, message: string) {
    iziToast.warning({
      title: title,
      message: message,
    });
  }

  async Error(title: string, message: string) {
    iziToast.error({
      title: title,
      message: message,
    });
  }
}

export default new ToastService();
