/* eslint-disable */
import AuthService from "../services/auth/AuthService";

const user = JSON.parse("" + localStorage.getItem("user"));

const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login(context: any, user: any) {
      return AuthService.login(user).then(
        (user) => {
          context.commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          context.commit("loginFailure");
          return Promise.reject(error.response.data);
        }
      );
    },
    logout(context: any) {
      AuthService.logout();
      context.commit("logout");
    },
  },
  mutations: {
    loginSuccess(state: any, user: any) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    loginFailure(state: any) {
      state.status = {};
      state.user = null;
    },
    logout(state: any) {
      state.status = {};
      state.user = null;
    },
    registerSuccess(state: any) {
      state.status = {};
    },
    registerFailure(state: any) {
      state.status = {};
    },
  },
};
