import Group from "./Group";

export default class User {
  public id?: string;
  public username = "";
  public email = "";
  public groups: Group[] = [];
  public is_staff = false;
  public is_active = false;
  public is_superuser = false;

  // JWT AuthTokens
  public access = "";
  public refresh = "";

  constructor(user?: User) {
    if (user === undefined || user === null) {
      return;
    }
    this.id = user?.id;
    this.username = user.username;
    this.email = user.username;
    this.groups = user.groups;
    this.is_staff = user.is_staff;
    this.is_active = user.is_active;
    this.is_superuser = user.is_superuser;

    this.access = user.access;
    this.refresh = user.refresh;
  }
}
