/* eslint-disable */
import Starter from "@/models/sportevent/Starter";
import StarterService from "@/services/sportevent/StarterService";

const storage_name = "starters";
let entries = JSON.parse("" + localStorage.getItem(storage_name));

function sortEntries(a: Starter, b: Starter): number {
  if (a.person.firstname < b.person.firstname) return -1;
  if (a.person.firstname > b.person.firstname) return 1;
  if (a.person.lastname < b.person.lastname) return -1;
  if (a.person.lastname > b.person.lastname) return 1;
  return 0;
}

export default {
  namespaced: true,
  state: entries ? { entries } : { entries: [] },
  actions: {
    setAll(context: any, entries: Starter[]): void {
      //console.log(entries);
      context.commit("setAllMutation", entries);
      context.commit("safeState");
    },
    add(context: any, entry: Starter): void {
      context.commit("addMutation", entry);
      context.commit("safeState");
    },
    delete(context: any, idToDelete: string): void {
      context.commit("deleteMutation", idToDelete);
      context.commit("safeState");
    },
    update(context: any, entry: Starter): void {
      context.commit("updateMutation", entry);
      context.commit("safeState");
    },
    clear(context: any): void {
      context.commit("setAllMutation", []);
      context.commit("safeState");
    },
  },
  mutations: {
    safeState(state: any): void {
      localStorage.setItem(storage_name, JSON.stringify(state.entries));
    },
    setAllMutation(state: any, entries: Starter[]) {
      if (entries !== undefined) {
        state.entries = entries.sort(sortEntries);
      } else {
        state.entries = [];
      }
    },
    addMutation(state: any, entry: Starter) {
      state.entries.push(entry);
      state.entries = state.entries.sort(sortEntries);
    },
    updateMutation(state: any, entry: Starter): void {
      if (entry.id !== undefined) {
        let index = state.entries.findIndex((e: Starter) => e.id == entry.id);
        state.entries[index] = entry;
      }
    },
    deleteMutation(state: any, delEntryId: string) {
      if (delEntryId !== undefined) {
        state.entries = state.entries.filter(
          (e: Starter) => e.id !== delEntryId
        );
      }
    },
  },
};
