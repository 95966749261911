<template>
  <div class="logobox">
    <span class="logo"></span>
    <h1>Sportcalc</h1>
  </div>
</template>

<script>
export default {
  name: "LogoBox",
};
</script>
