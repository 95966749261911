import Vue from "vue";
import Vuex from "vuex";
import { auth } from "./auth.module";
import localstore_sportevent from "./localstore_sportevent.module";
import localstore_competitions from "./localstore_competitions.module";
import localstore_starters from "./localstore_starters.module";
import localstore_persons from "./localstore_persons.module";
import localstore_societies from "./localstore_societies.module";
import localstore_startgroups from "./localstore_startgroups.module";
import localstore_sporteventcompetitions from "./localstore_sporteventcompetitions.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    localstore_sportevent,
    localstore_competitions,
    localstore_starters,
    localstore_persons,
    localstore_societies,
    localstore_startgroups,
    localstore_sporteventcompetitions,
  },
});
