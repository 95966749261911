<template>
  <div v-if="$store.state.auth.user">
    <b-navbar toggleable="lg" type="dark" variant="primary">
      <b-navbar-brand :href="$route.path"
        >{{ $route.meta.title }}
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" class="navbar-right"
        >☲</b-navbar-toggle
      >
      <b-collapse id="nav-collapse" is-nav>
        <!--
        <b-navbar-nav>
          <b-nav-item href="#">Link</b-nav-item>
        </b-navbar-nav>
        -->
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!-- 
          <b-nav-item-dropdown text="Lang" right>
            <b-dropdown-item
              v-for="entry in languages"
              :key="entry.title"
              @click="changeLocale(entry.language)"
            >
              {{ entry.title }}</b-dropdown-item
            >
          </b-nav-item-dropdown>
          -->

          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content>{{
              currentUser.username
            }}</template>
            <b-dropdown-item to="/profile">{{
              $t("basedata.profile")
            }}</b-dropdown-item>
            <b-dropdown-item to="/users" v-if="currentUser.is_superuser">{{
              $t("basedata.users")
            }}</b-dropdown-item>
            <b-dropdown-item to="/logout">{{
              $t("basedata.logout")
            }}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
/* eslint-disable */
import i18n from "@/plugins/i18n";

export default {
  name: "profile",
  data() {
    return {
      languages: [
        { flag: "at", language: "de", title: "Deutsch" },
        { flag: "en", language: "en", title: "English" },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
