
import { Component, Vue } from "vue-property-decorator";
import TheSidebarMenu from "./views/layout/TheSidebarMenu.vue";
import TheSporteventSidebarMenu from "./views/layout/TheSporteventSidebarMenu.vue";
import TheTopNavBar from "./views/layout/TheTopNavBar.vue";
import LogoBox from "./views/layout/LogoBox.vue";
import AuthService from "./services/auth/AuthService";
import PrintSettingsService from "./services/helper/PrintSettingsService";
import formatDate from "./plugins/formatDate";
import moment from "moment";

@Component({
  components: {
    TheSidebarMenu,
    TheTopNavBar,
    TheSporteventSidebarMenu,
    LogoBox,
  },
  filters: {
    formatDate,
  },
})
export default class App extends Vue {
  public activeSiteBar = false;
  public printheaderService = PrintSettingsService;

  mounted(): void {
    setInterval(() => {
      AuthService.refresh();
    }, 1000 * 60 * 5); // 5 min
    this.setPrintHeader();
  }

  toggleSidebar(): void {
    this.activeSiteBar = !this.activeSiteBar;
  }

  getDate(): string {
    let date = moment
      .utc(this.$store.state.localstore_sportevent.sportevent.eventDate)
      .format("DD.MM.YYYY");
    //console.log("converted date", date);
    return date;
  }

  public async setPrintHeader(hide = false): Promise<void> {
    await this.printheaderService.setHeader(hide);
  }
}
