/* eslint-disable */

import Competition from "@/models/sporteventCalculation/Competition";

const storage_name = "competitions";
let entries = JSON.parse("" + localStorage.getItem(storage_name));

function sortEntries(a: Competition, b: Competition): number {
  return a.name > b.name ? 1 : -1 ? 1 : -1;
}

export default {
  namespaced: true,
  state: entries ? { entries } : { entries: [] },
  actions: {
    setAll(context: any, entries: Competition[]): void {
      console.log(entries);
      context.commit("setAllMutation", entries);
      context.commit("safeState");
    },
    add(context: any, entry: Competition): void {
      context.commit("addMutation", entry);
      context.commit("safeState");
    },
    delete(context: any, idToDelete: string): void {
      context.commit("deleteMutation", idToDelete);
      context.commit("safeState");
    },
    update(context: any, entry: Competition): void {
      context.commit("updateMutation", entry);
      context.commit("safeState");
    },
    clear(context: any): void {
      context.commit("setAllMutation", []);
      context.commit("safeState");
    },
  },
  mutations: {
    safeState(state: any): void {
      localStorage.setItem(storage_name, JSON.stringify(state.entries));
    },
    setAllMutation(state: any, entries: Competition[]) {
      if (entries !== undefined) {
        state.entries = entries.sort(sortEntries);
      } else {
        state.entries = [];
      }
    },
    addMutation(state: any, entry: Competition) {
      state.entries.push(entry);
      state.entries = state.entries.sort(sortEntries);
    },
    updateMutation(state: any, entry: Competition): void {
      if (entry.id !== undefined) {
        let index = state.entries.findIndex(
          (e: Competition) => e.id == entry.id
        );
        state.entries[index] = entry;
      }
    },
    deleteMutation(state: any, delEntryId: string) {
      if (delEntryId !== undefined) {
        state.entries = state.entries.filter(
          (e: Competition) => e.id !== delEntryId
        );
      }
    },
  },
};
