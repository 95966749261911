import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  de: {
    welcomeMsg: "Willkommen",
    labels: {
      filtertext: "Tippe um zu suchen",
      filtertextStartgroup: "Suchen nach Riegen oder Personen",
      filterclear: "Filter löschen",
      name: "Name",
      longname: "Langer Name",
      shortname: "Kurzname",
      pluralName: "Plural Name",
      save: "Speichern",
      saved: "Gespeichert",
      delete: "Löschen",
      add: "Hinzufügen",
      back: "Zurück",
      csvimport: "Starte CSV import",
      xlsximport: "Starte XLSX import",
      becareful:
        "Bitte vorsichtig mit der Bearbeitung in diesem Bereich, damit könnte die Berechnung von Ergebnissen falsch oder gar nicht mehr möglich sein.",
      selectone: "Bitte eines auswählen, Tippe um zu suchen...",
      value: "Wert",
      points: "Punkte",
      pointsshort: "pkt.",
      open: "Öffnen",
      edit: "Bearbeiten",
      age: "Alter",
      confirmDelete: "Möchten Sie wirklich löschen?",
      of: "von",
      sort_by: "Sortierung nach",
    },
    basedata: {
      profile: "Profil",
      logout: "Abmelden",
      username: "Benutzer",
      user: "Benutzer",
      users: "Benutzer",
      email: "E-Mail",
      active: "Aktiv",
      staff: "Mitarbeiter",
      superuser: "Administrator",
    },
    societymgmt: {
      societymgmt: "Vereinsverwaltung",
      editsociety: "Verein bearbeiten",
      society: "Verein",
      societies: "Vereine",
      members: "Mitglieder",
      shortname: "Kurname",
      person: "Person",
      personedit: "Person bearbeiten",
      persons: "Personen",
      firstname: "Vorname",
      lastname: "Nachname",
      dateOfBirth: "Geburtstag",
      gender: "Geschlecht",
      male: "Männlich",
      female: "Weiblich",
      both: "Beides",
      ageFrom: "Alter von",
      ageTo: "Alter bis",
      fromTheAge: "Ab dem Alter von",
    },
    sporteventCalculation: {
      eventcalculation: "Ergebnisberechnung",
      competition: "Bewerb",
      competitions: "Bewerbe",
      agegrendergroup: "Alter/Geschlechts Gruppe",
      agegrendergroups: "Alter/Geschlechts Gruppen",
      units: "Einheiten",
      unit: "Einheit",
      judgeTypes: "Kampfrichterarten",
      judgeType: "Kampfrichterart",
      disciplineCategories: "Disziplin-Kategorien",
      disciplineCategory: "Disziplin-Kategorie",
      agePointCalculations: "Alterspunkte Berechnungen",
      agePointCalculation: "Alterspunkte Berechnung",
      pointsPerYearOfAge: "Punkte pro Lebensjahr",
      addToAValue: "Zum A-Wert hinzufügen",
      addToBValue: "Zum B-Wert hinzufügen",
      addToSum: "Zur Summe von A und B-Wert hinzufügen",
      exceedMaximumValuePoints: "Maximalwert von A oder B überschreiten",
      exceedMaximumTotalPoints: "Maximalwert der Gesamtpunkte überschreiten",
      disciplines: "Disziplinen",
      discipline: "Disziplin",
      highestValueIsTheBest: "Höhrere Wert ist besser",
      maximumAValue: "Maximaler A-Wert",
      minimumAValue: "Minimaler A-Wert",
      maximumBValue: "Maximaler B-Wert",
      repetition: "Wiederholdungen",
      hasPenaltyPoints: "Hat Sturz/Strafpunkte",
      hasAgePoints: "Hat Alterspunkte",
      multiplyAWithBPoints: "Multipliziere A mit B Punkten",
      hasBValueDivisor: "Hat einen B-Wert Divisor",
      hasBPoints: "Hat einen B-Wert",
      bValueDivisor: "Divisor für B Wert",
      calculationFunctions: "Berechnungsfunktionen",
      calculationFunction: "Berechnungsfunktion",
      startgroupNames: "Startgruppen-Namen",
      startgroupName: "Startgruppen-Name",
      nocalcfunction: "Keine Berechnungsfunktion für diese Alter/Gender-Gruppe",
      teamCalculations: "Mannschaftsberechnungen",
      teamCalculation: "Mannschaftsberechnung",
      minimumParticipants: "Minimum Teilnehmer",
      maximumParticipants: "Maximale Teilnehmer",
      maximumFemale: "Maximale weibliche Teilnehmer",
      maximumMale: "Maximale mänliche Teilnehmer",
      resultsForScoring: "Anzahl der besten Punkte/Ergebnisse pro Diziplin",
    },
    sportevent: {
      showPast: "Vergangene Anzeigen",
      currentAds: "Nur aktuelle Anzeigen",
      sportevent: "Veranstaltung",
      sportevents: "Veranstaltungen",
      eventDate: "Datum",
      endDate: "End-Datum",
      registrationDeadline: "Meldeschluss",
      availableCompetitions: "Verfügbare Bewerbe",
      selectedCompetitions: "Ausgewählte Bewerbe",
      dropDiscipline: "hier herziehen",
      deleteDiscipline: "Disziplin hierher ziehen zum Entfernen",
      starters: "Starter",
      starter: "Starter",
      teams: "Mannschaften",
      team: "Mannschaft",
      startsForSociety: "Für Verein",
      startBlocks: "Durchgänge/Kreise",
      startBlock: "Durchgang/Kreis",
      startTime: "Startzeit",
      endTime: "Endzeit",
      startBlockPosition: "Durchgang/Kreis Nummer",
      startBlockPositionAsPrefixInStartgroupName:
        "Durchgang/Kreis Nummer als Präfix für Riegennummer verwenden.",
      startGroupPositionBeginn: "Riegennummer starten mit",
      startGroup: "Startgruppe",
      startGroups: "Startgruppen",
      automaticStartGroupPosition: "Riegennummer automatisch vergeben",
      addStarterToStartGroup: "Starter hinzufügen",
      availableStarter: "Verfügbare Starter",
      selectedStarters: "Ausgewählte Starter",
      startingPosition: "Startposition",
      sporteventlists: "Veranstaltungslisten",
      listStartingGroup: "Riegenliste",
      enterpoints: "Punkte eintragen",
      penaltyPoints: "Sturz/Straf",
      aValue: "A-Wert",
      bValue: "B-Wert",
      rank: "Rang",
      resultsLists: "Ergebnislisten",
      calculatedPoints: "Berechnete Punkte",
      calculate: "Berechnen",
      resultListSingle: "Ergebnisliste",
      resultListSingleCommon: "Ergebnisliste o. AKs",
      resultListTeam: "Mannschaftliste",
      resultListTeamCommon: "Mannschaftliste Gesamt",
      listStarters: "Startliste",
      yearOfBirth: "Jahrgang",
      numberOfJudges: "Anzahl der Kampfrichter",
      judges: "Kampfgericht",
      judge: "Kampfrichter",
      scoringsheets: "Wertungsblätter",
      performance: "Leistung",
      haveTeamCompetition: "Hat eine Mannschaftswertung",
      copyCompetitionFromOtherEvent:
        "Bewerbe und Disciplinen von einer anderen Veranstaltung kopieren",
      competitionOnlyOnOneFacility:
        "Alle Bewerbe auf einer Anlage"
    },
  },
  en: {
    welcomeMsg: "Welcome",
    labels: {
      filtertext: "Type to search",
      filtertextStartgroup: "Search for startgroup or person",
      filterclear: "Clear filter",
      name: "Name",
      longname: "Long name",
      shortname: "Short name",
      pluralName: "Plural name",
      save: "Save",
      saved: "Saved",
      delete: "Delete",
      add: "Add",
      back: "Back",
      csvimport: "Start CSV import",
      xlsximport: "Start XLSX import",
      becareful:
        "Please be careful with the editing in this area, so that the calculation of results could be wrong or no longer possible.",
      selectone: "Select one, type to search",
      value: "Value",
      points: "Points",
      pointsshort: "p.",
      open: "Open",
      edit: "Edit",
      age: "Age",
      confirmDelete: "Do you really want to delete?",
      of: "of",
      sort_by: "Order by",
    },
    basedata: {
      profile: "Profile",
      logout: "Logout",
      username: "Username",
      user: "User",
      users: "Users",
      email: "E-Mail",
      active: "Active",
      staff: "Staff",
      superuser: "Administrator",
    },
    societymgmt: {
      societymgmt: "Society administration",
      editsociety: "Edit society",
      society: "Society",
      societies: "Societies",
      members: "Members",
      shortname: "Short name",
      person: "Person",
      personedit: "Person edit",
      persons: "Persons",
      firstname: "First name",
      lastname: "Last name",
      dateOfBirth: "Date of Birth",
      gender: "Gender",
      male: "Male",
      female: "Female",
      both: "Both",
      ageFrom: "Age from",
      ageTo: "Age to",
      fromTheAge: "From the age",
    },
    sporteventCalculation: {
      eventcalculation: "Event calculation",
      competition: "Competition",
      competitions: "Competitions",
      agegrendergroup: "Age gender group",
      agegrendergroups: "Age gender groups",
      units: "Units",
      unit: "Unit",
      judgeTypes: "Judge types",
      judgeType: "Judge types",
      disciplineCategories: "Discipline categories",
      disciplineCategory: "Discipline categories",
      agePointCalculations: "Agepoints calculations",
      agePointCalculation: "Agepoints calculation",
      pointsPerYearOfAge: "Points per year of age",
      addToAValue: "Add to A-Value",
      addToBValue: "Add to B-Value",
      addToSum: "Add to Sum of A and B",
      exceedMaximumValuePoints: "Exceed maximum value points",
      exceedMaximumTotalPoints: "Exceed maximum total points",
      disciplines: "Disciplines",
      discipline: "Discipline",
      highestValueIsTheBest: "Highest value is the best",
      maximumAValue: "Maximum A-Value",
      minimumAValue: "Minimum A-Value",
      maximumBValue: "Maximum B-Value",
      repetition: "Repetitions",
      hasPenaltyPoints: "Has penalty points",
      hasAgePoints: "Has age points",
      multiplyAWithBPoints: "Multiply A with B points",
      hasBValueDivisor: "Has B value divisor",
      bValueDivisor: "B value divisor",
      hasBPoints: "Has B value",
      calculationFunctions: "Calculation functions",
      calculationFunction: "Calculation function",
      startgroupNames: "Startgroup-Names",
      startgroupName: "Startgroup-Name",
      nocalcfunction: "No Calculation-Function for this Age/Gender-Group",
      teamCalculations: "Team calculations",
      teamCalculation: "Team calculation",
      minimumParticipants: "Minimum participants",
      maximumParticipants: "Maximum participants",
      maximumFemale: "Maximum female",
      maximumMale: "Maximum male",
      resultsForScoring: "Results for scoring",
    },
    sportevent: {
      showPast: "Show past",
      currentAds: "Only current ads",
      sportevent: "Sportevent",
      sportevents: "Sportevents",
      eventDate: "Date",
      endDate: "End date",
      registrationDeadline: "Registration deadline",
      availableCompetitions: "Available competitions",
      selectedCompetitions: "Selected competitions",
      dropDiscipline: "drop here",
      deleteDiscipline: "Remove disciplin",
      starters: "Starters",
      starter: "Starter",
      teams: "Teams",
      team: "Team",
      startsForSociety: "Starts for society",
      startBlocks: "Startblocks",
      startBlock: "Startblock",
      startGroups: "Startgroups",
      startGroup: "Startgroup",
      automaticStartGroupPosition: "Do assign startgroup number automatically",
      addStarterToStartGroup: "Add starter",
      availableStarter: "Available starters",
      selectedStarters: "Selected starters",
      sporteventlists: "Event lists",
      listStartingGroup: "Starting group list",
      enterpoints: "Enter points",
      penaltyPoints: "Penalty points",
      aValue: "A-Value",
      bValue: "B-Value",
      rank: "Rank",
      calculatedPoints: "Calculated points",
      calculate: "Calculate",
      resultsLists: "Results lists",
      resultListSingle: "Results list single",
      resultListSingleCommon: "Common results list",
      resultListTeams: "Results list teams",
      listStarters: "List of starters",
      yearOfBirth: "Year",
      numberOfJudges: "Number of judges",
      judges: "Judges",
      judge: "Judge",
      scoringsheets: "Scoring sheets",
      performance: "Performance",
      haveTeamCompetition: "Has a team score",
      copyCompetitionFromOtherEvent:
        "Copy competitions and disciplines from another event",
      competitionOnlyOnOneFacility:
        "All competitions on one facility"
    },
  },
};

const i18n = new VueI18n({ locale: "de", fallbackLocale: "en", messages });

export default i18n;
