<template>
  <div>
    <sidebar-menu v-if="loaded" :menu="menu" :hideToggle="true"></sidebar-menu>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import VueSidebarMenu from "vue-sidebar-menu";
import i18n from "../../plugins/i18n";

Vue.use(VueSidebarMenu);
export default {
  data() {
    return {
      menu: [],
      localstore_sportevent: null,
      loaded: false,
    };
  },
  mounted() {
    if (
      this.$store.state.localstore_sportevent.sportevent != undefined &&
      this.$store.state.localstore_sportevent.sportevent != null
    ) {
      this.localstore_sportevent =
        this.$store.state.localstore_sportevent.sportevent;
    }

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "localstore_sportevent/setSportEventMutation") {
        this.localstore_sportevent = true;
        this.localstore_sportevent = state.localstore_sportevent.sportevent;
        this.loaded = false;
        setTimeout(() => {
          this.getMenu();
        }, 20);
      }
    });
    this.getMenu();
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    getMenu() {
      this.loaded = true;
      const base_url = "/sportevent";
      if (this.localstore_sportevent != null) {
        this.menu = [
          {
            href: `${base_url}/`,
            title: this.localstore_sportevent.name,
            child: [],
          },
        ];
        if (
          this.$store.state.auth.user.is_staff == true &&
          this.$store.state.auth.user.is_superuser == false
        ) {
          this.menu[0].child.push({
            href: `${base_url}/scoreingstartgroups`,
            title: i18n.tc("sportevent.enterpoints"),
          });
        }
        if (this.$store.state.auth.user.is_superuser) {
          this.menu[0].child.push(
            {
              href: `${base_url}/disciplines`,
              title: i18n.tc("sporteventCalculation.disciplines"),
            },
            {
              href: `${base_url}/starters`,
              title: i18n.tc("sportevent.starters"),
            },
            {
              href: `${base_url}/teamcompetitions`,
              title: i18n.tc("sportevent.teams"),
              hidden: this.localstore_sportevent.haveTeamCompetition
                ? false
                : true,
            },
            {
              href: `${base_url}/startblocks`,
              title:
                `${this.localstore_sportevent.startgroupName.pluralName} ` +
                i18n.tc("labels.edit"),
            },
            //{
            //  href: `${base_url}/jury`,
            //  title: i18n.tc("sportevent.judges"),
            //},
            {
              href: `${base_url}/lists`,
              title: i18n.tc("sportevent.sporteventlists"),
              child: [
                {
                  href: `${base_url}/starter-list`,
                  title: i18n.tc("sportevent.listStarters"),
                },
                {
                  href: `${base_url}/startgroup-list`,
                  title: `${this.localstore_sportevent.startgroupName.pluralName}`,
                },
                //{
                //  href: `${base_url}/scoringsheets-by-startgroup`,
                //  title: `${i18n.tc("sportevent.scoringsheets")} Startg.`,
                //},
                {
                  href: `${base_url}/scoringsheets-by-disciplinecategory`,
                  title: `${i18n.tc("sportevent.scoringsheets")} Dis.`,
                },
              ],
            },
            {
              href: `${base_url}/scoreingstartgroups`,
              title: i18n.tc("sportevent.enterpoints"),
            },
            {
              href: `${base_url}/scoreingteams`,
              title: "Mannschafts-Pkt. eintragen",
              hidden: this.localstore_sportevent.haveClubCompetition
                ? false
                : true,
            },

            {
              href: `${base_url}/results`,
              title: i18n.tc("sportevent.resultsLists"),
              child: [
                {
                  href: `${base_url}/singleresultlist`,
                  title: i18n.tc("sportevent.resultListSingle"),
                },
                {
                  href: `${base_url}/singleresultlist?t=common`,
                  title: i18n.tc("sportevent.resultListSingleCommon"),
                  hidden: this.localstore_sportevent.haveClubCompetition
                    ? true
                    : false,
                },
                {
                  href: `${base_url}/teamresultlist`,
                  title: i18n.tc("sportevent.resultListTeam"),
                  hidden: this.localstore_sportevent.haveTeamCompetition
                    ? false
                    : true,
                },
                {
                  href: `${base_url}/clubresultlist`,
                  title: i18n.tc("sportevent.resultListTeam"),
                  hidden: this.localstore_sportevent.haveClubCompetition
                    ? false
                    : true,
                },
                {
                  href: `${base_url}/export-oetb-wien`,
                  title: "Export",
                  hidden: this.localstore_sportevent.haveClubCompetition
                    ? true
                    : false,
                },
              ],
            }
          );
        }
      }
    },
  },
};
</script>
