import HttpService from "../HttpService";
import Sportevent from "@/models/sportevent/Sportevent";

import store from "../../store/index";
import localstore_sportevent from "../../store/localstore_sportevent.module";
import SporteventCompetition from "@/models/sportevent/SporteventCompetition";

class SporteventService {
  public serviceUrl = "sportevent/sportevent/";
  async getAll() {
    const response = await HttpService.get<Sportevent[]>(`sportevent/all/`);
    if (response == undefined) return [];
    return response;
  }

  get(): Sportevent {
    if (localstore_sportevent.state.sportevent !== undefined) {
      return localstore_sportevent.state.sportevent;
    }
    return new Sportevent();
  }

  async clearAllStores(): Promise<void> {
    await store.dispatch("localstore_competitions/clear");
    await store.dispatch("localstore_starters/clear");
    await store.dispatch("localstore_persons/clear");
    await store.dispatch("localstore_societies/clear");
    await store.dispatch("localstore_startgroups/clear");
    await store.dispatch("localstore_sporteventcompetitions/clear");
  }

  async saveSporteventToStore(sportevent: Sportevent): Promise<void> {
    await store.dispatch("localstore_sportevent/set", sportevent);
  }

  async getWithApi(id: string): Promise<Sportevent> {
    const sportevent = new Sportevent(
      await HttpService.get<Sportevent>(`${this.serviceUrl}${id}/`)
    );
    try {
      sportevent.sporteventCompetitions = sportevent.sporteventCompetitions.sort(
        (a: SporteventCompetition, b: SporteventCompetition) =>
          a.competition.ageGenderGroup.gender >
          b.competition.ageGenderGroup.gender
            ? 1
            : a.competition.ageGenderGroup.gender ===
              b.competition.ageGenderGroup.gender
            ? a.competition.ageGenderGroup.ageFrom >
              b.competition.ageGenderGroup.ageFrom
              ? 1
              : a.competition.ageGenderGroup.ageFrom ===
                b.competition.ageGenderGroup.ageFrom
              ? a.competition.ageGenderGroup.ageTo >
                b.competition.ageGenderGroup.ageTo
                ? 1
                : -1
              : -1
            : -1
      );
      sportevent.sporteventCompetitions.forEach(function (
        sporteventCompetition: SporteventCompetition
      ) {
        sporteventCompetition.disciplines.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
      });
    } catch {
      console.log("Fehler beim sortieren der sporteventCompetitions");
    }
    return sportevent;
  }

  async copyCompetitions(id: string, copyFromId: string): Promise<Sportevent> {
    await HttpService.get<Sportevent>(
      `${this.serviceUrl}copy-competitions/${id}/${copyFromId}/`
    );
    return await this.getWithApi(id);
  }

  async save(sportevent: Sportevent): Promise<Sportevent | null> {
    let response: Sportevent | undefined = undefined;
    if (sportevent.id === undefined) {
      response = await HttpService.post<Sportevent>(
        this.serviceUrl,
        sportevent
      );
    } else {
      response = await HttpService.put<Sportevent>(
        `${this.serviceUrl}${sportevent.id}/`,
        sportevent
      );
    }
    if (response !== undefined) return response;
    return new Sportevent();
  }
}

export default new SporteventService();
