import ConfigProvider from "../ConfigProvider";

export default function GetApiBaseUrl(): string {
  let apiurl = "http://127.0.0.1:8001/api/";
  const apiUrlEnv = ConfigProvider.value("backendHost");
  if (apiUrlEnv != "") {
    apiurl = apiUrlEnv;
  }
  return apiurl;
}
