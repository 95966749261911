import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import i18n from "../plugins/i18n";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: "/", redirect: { name: "Home" } },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { title: "Home" },
  },

  // Basedata
  {
    path: "/login",
    name: "Login",
    meta: { title: "Login" },
    component: () => import("../views/base/user/Login.vue"),
  },
  {
    path: "/logout",
    name: "Logout",

    component: () => import("../views/base/user/Logout.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { title: i18n.tc("basedata.profile") },
    component: () => import("../views/base/user/Profile.vue"),
  },
  {
    path: "/users",
    name: "Users",
    meta: { title: i18n.tc("basedata.username") },
    component: () => import("../views/base/user/Users.vue"),
  },

  // AppPages
  {
    path: "/societies",
    name: "Societies",
    meta: { title: i18n.tc("societymgmt.societies") },
    component: () => import("../components//societymgmt/Societies.vue"),
    children: [
      {
        path: ":id",
        name: "Society",
        meta: { title: i18n.tc("societymgmt.society") },
        component: () => import("../components/societymgmt/Society.vue"),
      },
    ],
  },
  {
    path: "/persons",
    name: "Persons",
    meta: { title: i18n.tc("societymgmt.persons") },
    component: () => import("../components/societymgmt/Persons.vue"),
    children: [
      {
        path: ":id",
        name: "Person",
        meta: { title: i18n.tc("societymgmt.person") },
        component: () => import("../components/societymgmt/Person.vue"),
      },
    ],
  },
  {
    path: "/competitions",
    name: "Competitions",
    meta: { title: i18n.tc("sporteventCalculation.competitions") },
    component: () =>
      import("../components/sporteventCalculation/Competitions.vue"),
    children: [
      {
        path: ":id",
        name: "Competition",
        meta: { title: i18n.tc("sporteventCalculation.competition") },
        component: () =>
          import("../components/sporteventCalculation/Competition.vue"),
      },
    ],
  },
  {
    path: "/agegendergroups",
    name: "Agegendergroups",
    meta: { title: i18n.tc("sporteventCalculation.agegrendergroups") },
    component: () =>
      import("../components/sporteventCalculation/AgeGenderGroups.vue"),
    children: [
      {
        path: ":id",
        name: "Agegendergroup",
        meta: { title: i18n.tc("sporteventCalculation.agegrendergroup") },
        component: () =>
          import("../components/sporteventCalculation/AgeGenderGroup.vue"),
      },
    ],
  },
  {
    path: "/agepointcalculations",
    name: "AgePointCalculations",
    meta: { title: i18n.tc("sporteventCalculation.agePointCalculations") },
    component: () =>
      import("../components/sporteventCalculation/AgePointCalculations.vue"),
    children: [
      {
        path: ":id",
        name: "AgePointCalculation",
        meta: { title: i18n.tc("sporteventCalculation.agePointCalculation") },
        component: () =>
          import("../components/sporteventCalculation/AgePointCalculation.vue"),
      },
    ],
  },
  {
    path: "/units",
    name: "Units",
    meta: { title: i18n.tc("sporteventCalculation.units") },
    component: () => import("../components/sporteventCalculation/Units.vue"),
    children: [
      {
        path: ":id",
        name: "Unit",
        meta: { title: i18n.tc("sporteventCalculation.unit") },
        component: () => import("../components/sporteventCalculation/Unit.vue"),
      },
    ],
  },
  {
    path: "/judgetypes",
    name: "JudgeTypes",
    meta: { title: i18n.tc("sporteventCalculation.judgeTypes") },
    component: () =>
      import("../components/sporteventCalculation/JudgeTypes.vue"),
    children: [
      {
        path: ":id",
        name: "JudgeType",
        meta: { title: i18n.tc("sporteventCalculation.judgeType") },
        component: () =>
          import("../components/sporteventCalculation/JudgeType.vue"),
      },
    ],
  },
  {
    path: "/disciplinecategories",
    name: "DisciplineCategories",
    meta: { title: i18n.tc("sporteventCalculation.disciplineCategories") },
    component: () =>
      import("../components/sporteventCalculation/DisciplineCategories.vue"),
    children: [
      {
        path: ":id",
        name: "DisciplineCategory",
        meta: { title: i18n.tc("sporteventCalculation.disciplineCategory") },
        component: () =>
          import("../components/sporteventCalculation/DisciplineCategory.vue"),
      },
    ],
  },
  {
    path: "/disciplines",
    name: "Disciplines",
    meta: { title: i18n.tc("sporteventCalculation.disciplines") },
    component: () =>
      import("../components/sporteventCalculation/Disciplines.vue"),
    children: [
      {
        path: ":id",
        name: "Discipline",
        meta: { title: i18n.tc("sporteventCalculation.discipline") },
        component: () =>
          import("../components/sporteventCalculation/Discipline.vue"),
      },
    ],
  },
  {
    path: "/calculationfunctions/:discipline_id",
    name: "CalculationFunctions",
    meta: { title: i18n.tc("sporteventCalculation.calculationFunctions") },
    component: () =>
      import("../components/sporteventCalculation/CalculationFunctions.vue"),
    children: [
      {
        path: ":id",
        name: "CalculationFunction",
        meta: { title: i18n.tc("sporteventCalculation.calculationFunction") },
        component: () =>
          import("../components/sporteventCalculation/CalculationFunction.vue"),
      },
    ],
  },
  {
    path: "/startgroupnames/",
    name: "StartgroupNames",
    meta: { title: i18n.tc("sporteventCalculation.startgroupNames") },
    component: () =>
      import("../components/sporteventCalculation/StartgroupNames.vue"),
    children: [
      {
        path: ":id",
        name: "StartgroupName",
        meta: { title: i18n.tc("sporteventCalculation.startgroupName") },
        component: () =>
          import("../components/sporteventCalculation/StartgroupName.vue"),
      },
    ],
  },
  {
    path: "/teamcalculations/",
    name: "TeamCalculations",
    meta: { title: i18n.tc("sporteventCalculation.teamCalculations") },
    component: () =>
      import("../components/sporteventCalculation/TeamCalculations.vue"),
    children: [
      {
        path: ":id",
        name: "TeamCalculation",
        meta: { title: i18n.tc("sporteventCalculation.teamCalculation") },
        component: () =>
          import("../components/sporteventCalculation/TeamCalculation.vue"),
      },
    ],
  },

  // Sportevent
  {
    path: "/sportevents/",
    name: "Sportevents",
    meta: { title: i18n.tc("sportevent.sportevents") },
    component: () => import("../components/sportevent/Sportevents.vue"),
  },
  {
    path: "/sportevent/",
    name: "Sportevent",
    meta: { title: i18n.tc("sportevent.sportevent") },
    component: () => import("../components/sportevent/Sportevent.vue"),
  },
  {
    path: "/sportevent/modal/",
    name: "SporteventModal",
    meta: { title: i18n.tc("sportevent.sportevent") },
    component: () => import("../components/sportevent/SporteventModal.vue"),
  },
  {
    path: "/sportevent/copy-competition-from",
    name: "SporteventCopyFrom",
    meta: { title: i18n.tc("sportevent.sportevent") },
    component: () =>
      import("../components/sportevent/CopySporteventCompetitions.vue"),
  },
  {
    path: "/sportevent/disciplines",
    name: "SporteventDisciplines",
    meta: { title: i18n.tc("sporteventCalculation.disciplines") },
    component: () =>
      import("../components/sportevent/SporteventCompetitions.vue"),
  },
  {
    path: "/sportevent/disciplines-next",
    name: "SporteventDisciplines-next",
    meta: { title: i18n.tc("sporteventCalculation.disciplines") },
    component: () =>
      import("../components/sportevent/SporteventCompetitionsNext.vue"),
  },
  {
    path: "/sportevent/starters",
    name: "Starters",
    meta: { title: i18n.tc("sportevent.starters") },
    component: () => import("../components/sportevent/Starters.vue"),
    children: [
      {
        path: ":id",
        name: "Starter",
        meta: { title: i18n.tc("sportevent.starter") },
        component: () => import("../components/sportevent/Starter.vue"),
      },
    ],
  },
  {
    path: "/sportevent/teamcompetitions",
    name: "TeamCompetitions",
    meta: { title: i18n.tc("sportevent.teams") },
    component: () => import("../components/sportevent/TeamCompetitions.vue"),
    children: [
      {
        path: ":id",
        name: "TeamCompetition",
        meta: { title: i18n.tc("sporteventCalculation.competition") },
        component: () => import("../components/sportevent/TeamCompetition.vue"),
      },
      {
        path: ":teamCompetitionId/team/:id",
        name: "Team",
        meta: { title: i18n.tc("sportevent.team") },
        component: () => import("../components/sportevent/Team.vue"),
      },
    ],
  },
  {
    path: "/sportevent/startblocks",
    name: "Startblocks",
    meta: { title: i18n.tc("sportevent.startBlocks") },
    component: () => import("../components/sportevent/StartBlocks.vue"),
    children: [
      {
        path: ":id",
        name: "Startblock",
        meta: { title: i18n.tc("sportevent.startBlock") },
        component: () => import("../components/sportevent/StartBlock.vue"),
      },
      {
        path: ":startBlockId/startgroup/:id",
        name: "Startgroup",
        meta: { title: i18n.tc("sportevent.startGroup") },
        component: () => import("../components/sportevent/StartGroup.vue"),
      },
    ],
  },
  {
    path: "/sportevent/jury",
    name: "Jury",
    meta: { title: i18n.tc("sportevent.judges") },
    component: () => import("../components/sportevent/Juries.vue"),
  },
  {
    path: "/sportevent/lists",
    name: "SporteventLists",
    meta: { title: i18n.tc("sportevent.sporteventlists") },
    component: () => import("../components/sportevent/Lists.vue"),
  },
  {
    path: "/sportevent/startgroup-list",
    name: "ListStartingGroup",
    meta: { title: i18n.tc("sportevent.listStartingGroup") },
    component: () => import("../components/sportevent/ListStartGroup.vue"),
  },
  {
    path: "/sportevent/starter-list",
    name: "ListStarters",
    meta: { title: i18n.tc("sportevent.listStarters") },
    component: () => import("../components/sportevent/ListStarters.vue"),
  },
  {
    path: "/sportevent/scoringsheet-list",
    name: "ListScoringSheet",
    meta: { title: i18n.tc("sportevent.scoringsheets") },
    component: () => import("../components/sportevent/ListScoringSheet.vue"),
  },
  {
    path: "/sportevent/scoringsheets-by-disciplinecategory",
    name: "ListScoringSheetsByDisciplineCategory",
    meta: { title: i18n.tc("sportevent.scoringsheets") },
    component: () =>
      import(
        "../components/sportevent/ListScoringSheetsByDisciplineCategory.vue"
      ),
  },
  {
    path: "/sportevent/scoringsheets-by-startgroup",
    name: "ListScoringSheetsByStartgroup",
    meta: { title: i18n.tc("sportevent.scoringsheets") },
    component: () =>
      import(
        "../components/sportevent/ListScoringSheetsByStartgroup.vue"
      ),

  },
  {
    path: "/sportevent/scoreingstartgroups",
    name: "ScoringStartgroups",
    meta: { title: i18n.tc("sportevent.enterpoints") },
    component: () => import("../components/sportevent/ScoringStartgroups.vue"),
    children: [
      {
        path: "scoreing/:startgroupId/:disciplineCategoryId/",
        name: "ScoringModal",
        meta: { title: i18n.tc("sportevent.enterpoints") },
        component: () => import("../components/sportevent/ScoringModal.vue"),
      },
    ],
  },
  {
    path: "/sportevent/scoreingteams",
    name: "ScoringTeam",
    meta: { title: "Mannschafts-Pkt. eintragen" },
    component: () => import("../components/sportevent/ScoringTeam.vue"),
  },
  
  {
    path: "/sportevent/results",
    name: "ResultLists",
    meta: { title: i18n.tc("sportevent.resultListSingle") },
    component: () => import("../components/sportevent/ListResults.vue"),
  },
  {
    path: "/sportevent/singleresultlist",
    name: "ListResultsSingle",
    meta: { title: i18n.tc("sportevent.resultListSingle") },
    component: () => import("../components/sportevent/ListResultsSingle.vue"),
  },
  {
    path: "/sportevent/singleresultlist?t=common",
    name: "ListResultsSingleCommon",
    meta: { title: i18n.tc("sportevent.resultListSingle") },
    component: () => import("../components/sportevent/ListResultsSingle.vue"),
  },
  {
    path: "/sportevent/teamresultlist",
    name: "ListResultsTeam",
    meta: { title: i18n.tc("sportevent.resultListTeam") },
    component: () => import("../components/sportevent/ListResultsTeam.vue"),
  },
  {
    path: "/sportevent/clubresultlist",
    name: "ListResultsTeam",
    meta: { title: i18n.tc("sportevent.resultListTeam") },
    component: () => import("../components/sportevent/ListResultsClubCompetition.vue"),
  },
  {
    path: "/sportevent/export-oetb-wien",
    name: "ExportOetbWien",
    meta: { title: "Export ÖTB Wien" },
    component: () => import("../components/sportevent/ExportOetbWien.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");
  // try to access a restricted page + not logged in
  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});

export default router;
