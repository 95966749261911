<template>
  <sidebar-menu v-if="loaded" :menu="menu" :hideToggle="true"></sidebar-menu>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import VueSidebarMenu from "vue-sidebar-menu";
import i18n from "../../plugins/i18n";

Vue.use(VueSidebarMenu);
export default {
  data() {
    return {
      menu: [],
      loaded: false,
    };
  },
  methods: {
    getMenu() {
      // Staff
      if (this.$store.state.auth.user.is_staff == true) {
        this.menu.push({
          href: "/sportevents",
          title: i18n.tc("sportevent.sportevents"),
        });
      }
      // Admin
      if (this.$store.state.auth.user.is_superuser) {
        // Settings eventcalculation
        this.menu.push({
          href: "/disciplines",
          title: i18n.tc("sporteventCalculation.eventcalculation"),
          child: [
            {
              href: "/disciplines",
              title: i18n.tc("sporteventCalculation.disciplines"),
            },
            {
              href: "/competitions",
              title: i18n.tc("sporteventCalculation.competitions"),
            },
            {
              href: "/agegendergroups",
              title: i18n.tc("sporteventCalculation.agegrendergroups"),
            },
            {
              href: "/judgetypes",
              title: i18n.tc("sporteventCalculation.judgeTypes"),
            },
            {
              href: "/units",
              title: i18n.tc("sporteventCalculation.units"),
            },
            {
              href: "/disciplinecategories",
              title: i18n.tc("sporteventCalculation.disciplineCategories"),
            },
            {
              href: "/agepointcalculations",
              title: i18n.tc("sporteventCalculation.agePointCalculations"),
            },
            {
              href: "/startgroupnames",
              title: i18n.tc("sporteventCalculation.startgroupNames"),
            },
            {
              href: "/teamcalculations",
              title: i18n.tc("sporteventCalculation.teamCalculations"),
            },
          ],
        });

        // societymgmt
        this.menu.push({
          href: "/societies",
          title: i18n.tc("societymgmt.societymgmt"),
          child: [
            {
              href: "/societies",
              title: i18n.tc("societymgmt.societies"),
            },
            {
              href: "/persons",
              title: i18n.tc("societymgmt.persons"),
            },
          ],
        });
      }
      this.loaded = true;
    },
  },
  mounted() {
    this.getMenu();
  },
};
</script>
