/* eslint-disable */

import Person from "@/models/societymgmt/Person";

const storage_name = "persons";
let entries = JSON.parse("" + localStorage.getItem(storage_name));

function sortEntries(a: Person, b: Person): number {
  return a.firstname > b.firstname ? 1 : -1 ? 1 : -1;
}

export default {
  namespaced: true,
  state: entries ? { entries } : { entries: [] },
  actions: {
    setAll(context: any, entries: Person[]): void {
      console.log(entries);
      context.commit("setAllMutation", entries);
      context.commit("safeState");
    },
    add(context: any, entry: Person): void {
      context.commit("addMutation", entry);
      context.commit("safeState");
    },
    delete(context: any, idToDelete: string): void {
      context.commit("deleteMutation", idToDelete);
      context.commit("safeState");
    },
    update(context: any, entry: Person): void {
      context.commit("updateMutation", entry);
      context.commit("safeState");
    },
    clear(context: any): void {
      context.commit("setAllMutation", []);
      context.commit("safeState");
    },
  },
  mutations: {
    safeState(state: any): void {
      localStorage.setItem(storage_name, JSON.stringify(state.entries));
    },
    setAllMutation(state: any, entries: Person[]) {
      if (entries !== undefined) {
        state.entries = entries.sort(sortEntries);
      } else {
        state.entries = [];
      }
    },
    addMutation(state: any, entry: Person) {
      state.entries.push(entry);
      state.entries = state.entries.sort(sortEntries);
    },
    updateMutation(state: any, entry: Person): void {
      if (entry.id !== undefined) {
        let index = state.entries.findIndex((e: Person) => e.id == entry.id);
        state.entries[index] = entry;
      }
    },
    deleteMutation(state: any, delEntryId: string) {
      if (delEntryId !== undefined) {
        state.entries = state.entries.filter(
          (e: Person) => e.id !== delEntryId
        );
      }
    },
  },
};
