import SporteventCompetition from "./SporteventCompetition";
import StartgroupName from "../sporteventCalculation/StartgroupName";
import Competition from "../sporteventCalculation/Competition";
import AgePointCalculation from "../sporteventCalculation/AgePointCalculation";

export default class Sportevent {
  id?: string;
  name = "";
  eventDate: Date = new Date();
  endDate: Date = new Date();
  registrationDeadline: Date  = new Date();
  startgroupName: StartgroupName = new StartgroupName();
  agePointCalculation: AgePointCalculation = new AgePointCalculation();
  competitions: Competition[] = [];
  sporteventCompetitions: SporteventCompetition[] = [];
  haveTeamCompetition = false;
  haveClubCompetition = false;
  oetbWienID = 0;
  competitionOnlyOnOneFacility = false;

  constructor(sportevent?: Sportevent) {
    if (sportevent === undefined || sportevent === null) {
      return;
    }
    this.id = sportevent.id;
    this.name = sportevent.name;
    this.eventDate = sportevent.eventDate;
    this.endDate = sportevent.endDate;
    this.registrationDeadline = sportevent.registrationDeadline;
    this.startgroupName = sportevent.startgroupName;
    this.agePointCalculation = sportevent.agePointCalculation;
    this.competitions = sportevent.competitions;
    this.sporteventCompetitions = sportevent.sporteventCompetitions;
    this.haveTeamCompetition = sportevent.haveTeamCompetition;
    this.haveClubCompetition = sportevent.haveClubCompetition;
    this.oetbWienID = sportevent.oetbWienID;
    this.competitionOnlyOnOneFacility = sportevent.competitionOnlyOnOneFacility;
  }
}
